import { useEffect, useMemo, useRef, useState } from "react";
import { getUsers } from "../../../routes/api";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faEye, faPlus } from "@fortawesome/free-solid-svg-icons";

export default function Users() {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getLastUsers();
  }, []);

  async function getLastUsers(params) {
    return getUsers({}).then((res) => {
      setUsers(res?.data?.data);
      setIsLoading(false);
      return true;
    });
  }

  const memoUsers = useMemo(() => users, [users]);

  return (
      <div>
        {!isLoading ? (
            <>
              <div className="flex justify-between items-center mb-4">
                <h1 className="text-xl font-bold"></h1>
                <Link
                    to="/auth/dashboard/add-user" // Update the path to your add user page
                    className="inline-flex items-center gap-x-1.5 rounded-md bg-green-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                >
                  <FontAwesomeIcon icon={faPlus} /> Add User
                </Link>
              </div>
              <table className="table-auto w-full border-collapse mt-4 text-sm">
                <thead>
                <tr>
                  <th>User ID</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Admin</th>
                  <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {memoUsers?.data?.map((item, index) => {
                  return (
                      <tr
                          key={index}
                          className="overflow-hidden whitespace-nowrap text-center border border-b-1 border-t-0 border-r-0 border-l-0"
                      >
                        <td className="overflow-hidden p-2">{item?.id}</td>
                        <td className="overflow-hidden p-2">{item?.name}</td>
                        <td className="overflow-hidden p-2">{item?.email}</td>
                        <td className="overflow-hidden p-2">
                          {item?.is_admin ? (
                              <span style={{ color: 'green' }}>✅</span> // Green Tick for true
                          ) : (
                              <span style={{ color: 'red' }}>❌</span>  // Red Cross for false
                          )}
                        </td>
                        <td className="overflow-hidden p-2 text-sm">
                          <Link
                              to={`/auth/dashboard/single-user/${item.id}`}
                              type="button"
                              className="inline-flex items-center mr-2 rounded-md bg-blue-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          >
                            <FontAwesomeIcon icon={faEye} />
                          </Link>
                          <Link
                              to={`/auth/dashboard/edit-user/${item.id}`}
                              type="button"
                              className="inline-flex mr-2 items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          >
                            <FontAwesomeIcon icon={faPenToSquare} />
                          </Link>
                        </td>
                      </tr>
                  );
                })}
                </tbody>
              </table>
            </>
        ) : (
            <table className="table-auto rounded w-full border-collapse mt-8 text-sm">
              <tbody>
              <tr className="rounded-md animate-pulse border border-1 border-b-black h-10 bg-gray-300">
                <td className="rounded-md"></td>
              </tr>
              {/* Add more loading rows here */}
              </tbody>
            </table>
        )}
      </div>
  );
}
