import { twMerge } from "tailwind-merge";

export default function Button(props) {
  return (
    <button
      onClick={props.handleSubmit}
      className={twMerge(
        "w-full p-2 rounded text-lg hover:bg-blue-500 transition",
        props.classes
      )}
      disabled={props.isLoading}
    >
      {props.isLoading ? "Please wait..." : props.innerText}
    </button>
  );
}
