import {useEffect, useMemo, useRef, useState} from "react";
import {
    getChannels,
    getPlays,
    updatePlay,
    exportPlays,
    deletePlay, savePlay,
} from "../../../routes/api";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {DropDown} from "../../../components/form/DropDown";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faSearch,
    faPenToSquare,
    faEye,
    faArrowRotateRight,
} from "@fortawesome/free-solid-svg-icons";
import {searchAction} from "../../../redux/slices/search";
import {
    formatResult,
    resultColorTotal,
    formatNumber,
    getOutcomes,
    resultColor,
} from "../../../utils/helpers";
import moment from "moment";
import {toast} from "react-toastify";
import Loader from "../../../components/mix/Loader";
import CalendarButton from "../../../components/form/CalendarButton";
import Calendar from "../../../components/form/Calendar";
import './Plays.css'; // Adjust the path as necessary
import { faChartSimple, faHourglassHalf, faTrash, faPlus, faDownload} from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import {hasPermission} from "../../../utils/permissions";

export default function Plays() {
    const dispatch = useDispatch();

    const [selectedPeriod, setSelectedPeriod] = useState(1);
    const [selectedChannelId, setSelectedChannelId] = useState(0);
    const [plays, setPlays] = useState([]);
    const [totalRiskedCompleted, setTotalRiskedCompleted] = useState([]);
    const [totalRiskedPending, setTotalRiskedPending] = useState([]);
    const [totalWonCompleted, setTotalWonCompleted] = useState([]);
    const [totalWonPending, setTotalWonPending] = useState([]);
    const [totalResult, setTotalResult] = useState([]);
    const [channels, setChannels] = useState([]);
    const [resultLoader, setResultLoader] = useState({
        id: 0,
        loading: false,
    });

    const location = useLocation(); // Get the current location

    const permissions = useSelector((state) => state.permissions);
    const isAdmin = useSelector((state) => state.auth.isAdmin);

    // Extract the 'new' query parameter
    const queryParams = new URLSearchParams(location.search);
    const isNew = queryParams.get("new") === "true"; // Check if the 'new' param is true
    let highlightLatest = 0;
    if (isNew) queryParams.delete("new");

    const outcomes = getOutcomes();

    const [isLoading, setIsLoading] = useState(true);
    const filters = useSelector((state) => state.search);

    // const start_date_input = useRef(null);
    // const end_date_input = useRef(null);
    //
    // if (start_date_input.current && filters.start_date)
    //     start_date_input.current.value = filters.start_date;
    // if (end_date_input.current && filters.end_date)
    //     end_date_input.current.value = filters.end_date;

    useEffect(() => {
        handlePeriodChange(1);
    }, [permissions, isAdmin]);

    async function getLastPlaysAndChannels(params, permissions, isAdmin) {
        getPlays({...params}).then((res) => {
            setPlays(res?.data?.data);
            setTotalWonCompleted(res?.data?.total_won_completed);
            setTotalWonPending(res?.data?.total_won_pending);
            setTotalRiskedCompleted(res?.data?.total_risked_completed);
            setTotalRiskedPending(res?.data?.total_risked_pending);
            setTotalResult(res?.data?.total_result);
            setIsLoading(false);
            return true;
        });
        getChannels().then((res) => {
            const allChannels = res?.data?.data?.data;

            // Filter channels based on `can_create_play` permission or if user is admin
            const filteredChannels = isAdmin
                ? allChannels
                : allChannels.filter(channel =>
                    hasPermission(permissions, channel.id, "can_view_play")
                );

            setChannels(filteredChannels);
        });
    }

    // const handlePagination = (page) => {
    //     setIsLoading(true);
    //     getPlays({page: page, ...filters});
    // };

    const handleOutcomeChange = async (e, id) => {
        let resultLoad = {
            id: id,
            loading: true,
        };
        setResultLoader(resultLoad);
        let value = e.target.value;
        const data = {
            outcome: value,
        };
        const res = await updatePlay(id, data);
        if (res?.data?.status == "success") {
            let result = getPlays({...filters}).then((res) => {
                setPlays(res?.data?.data);
                setTotalWonCompleted(res?.data?.total_won_completed);
                setTotalWonPending(res?.data?.total_won_pending);
                setTotalRiskedCompleted(res?.data?.total_risked_completed);
                setTotalRiskedPending(res?.data?.total_risked_pending);
                setTotalResult(res?.data?.total_result);
                setIsLoading(false);

                let resultLoad = {
                    id: 0,
                    loading: false,
                };
                setResultLoader(resultLoad);
                toast.success("Status Changed Successfully");
                return true;
            });
        }
        return true;
    };

    const handleDateRange = async (e) => {
        setIsLoading(true);
        let end_date = moment(e.selection.endDate).format("YYYY-MM-DD");
        let start_date = moment(e.selection.startDate).format("YYYY-MM-DD");
        dispatch(searchAction({start_date, end_date}));
        getLastPlaysAndChannels({...filters, start_date, end_date}, permissions, isAdmin);
    };

    const handlePeriodChange = async (period) => {
        setIsLoading(true);

        setSelectedPeriod(period);
        period = parseInt(period);
        setIsLoading(true);

        let start_date = moment().format("YYYY-MM-DD");
        let end_date = moment().format("YYYY-MM-DD");

        switch (period) {
            case 0: // Daily - Today
                start_date = moment().format("YYYY-MM-DD");
                end_date = moment().format("YYYY-MM-DD");
                break;
            case 1: // Weekly - This Week
                start_date = moment().startOf("isoWeek").format("YYYY-MM-DD");
                end_date = moment().endOf('isoWeek').format("YYYY-MM-DD");
                break;
            case 2: // Monthly
                start_date = moment().startOf('month').format("YYYY-MM-DD");
                end_date = moment().endOf('month').format("YYYY-MM-DD");
                break;
            case 3: // Yearly
                start_date = moment().startOf('year').format("YYYY-MM-DD");
                end_date = moment().endOf('year').format("YYYY-MM-DD");
                break;
            default:
                console.error("Invalid period");
                return false;
        }

        dispatch(searchAction({start_date, end_date}));
        getLastPlaysAndChannels({...filters, start_date, end_date}, permissions, isAdmin);
        return true;
    };

    const handleSelectChange = (event) => {
        let  channel_id = event.target.value;
        setSelectedChannelId(channel_id);
        dispatch(searchAction({channel_id}));
        getLastPlaysAndChannels({...filters, channel_id}, permissions, isAdmin);
    };

    const memofigures = useMemo(() => plays, [plays]);

    const handleDelete = (id) => {
        if (window.confirm('Are you sure you want to delete this record?')) {
            deletePlay(id)
                .then((res) => {
                    if (res.data.status === "success") {
                        toast.success(res.data.message);
                        getLastPlaysAndChannels(filters, permissions, isAdmin);
                    }
                })
                .catch((error) => {
                    if (error.response && error.response.status === 422) {
                        const validationErrors = error.response.data.errors;
                        for (const [field, messages] of Object.entries(validationErrors)) {
                            messages.forEach((message) => toast.error(`${message}`));
                        }
                    } else {
                        toast.error('An error occurred. Please try again.');
                    }
                });
        }
    };

    // Check if the user has `can_create_play` permission for any channel
    const hasCreatePermission = permissions.some(permission => permission.can_create_play);

    return (
        <div>
            <div className="flex justify-between items-center mb-4">
                <button
                    onClick={() => {
                        exportPlays(filters)
                            .then((response) => {
                                // Get the file name and type from the response headers
                                const contentDisposition = response.headers['content-disposition'];
                                const filename = contentDisposition
                                    ? contentDisposition.split('filename=')[1].replace(/"/g, '') // Extract filename
                                    : `${filters.start_date}_${filters.end_date}_BookMyLine`; // Fallback to filters' start and end dates

                                const blob = new Blob([response.data], { type: response.headers['content-type'] });
                                const url = window.URL.createObjectURL(blob);

                                const link = document.createElement('a');
                                link.href = url;
                                link.setAttribute('download', filename); // Use the dynamic filename
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link); // Cleanup
                            })
                            .catch((error) => {
                                console.error("Error exporting plays:", error);
                            });
                    }}
                    className="inline-flex items-center gap-x-1.5 rounded-md bg-blue-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                >
                    <FontAwesomeIcon icon={faDownload} /> Export Plays
                </button>
                {(isAdmin || hasCreatePermission) && (
                    <div className="flex justify-between items-center mb-4">
                        <h1 className="text-xl font-bold"></h1>
                        <Link
                            to="/auth/dashboard/add-play" // Update the path to your add user page
                            className="inline-flex items-center gap-x-1.5 rounded-md bg-green-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                        >
                            <FontAwesomeIcon icon={faPlus} /> Add Play
                        </Link>
                    </div>
                )}
            </div>
            <div className="grid grid-cols-10 gap-1 items-center">
                <div className="col-span-2">
                    <select
                        as="select"
                        name="channel_id"
                        className="form-control"
                        onChange={(e) => handleSelectChange(e)}
                    >
                        <option value="">Select Channel</option>
                        {channels.map(option => (
                            <option key={option.id} value={option.id}>
                                {option.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="col-span-5 flex justify-end gap-2 items-center">
                    <CalendarButton
                        btnText={"Daily"}
                        handleSubmit={() => handlePeriodChange(0)}
                        selected={selectedPeriod === 0}
                    />
                    <CalendarButton
                        btnText={"Weekly"}
                        handleSubmit={() => handlePeriodChange(1)}
                        selected={selectedPeriod === 1}
                    />
                    <CalendarButton
                        btnText={"Monthly"}
                        handleSubmit={() => handlePeriodChange(2)}
                        selected={selectedPeriod === 2}
                    />
                    <CalendarButton
                        btnText={"Yearly"}
                        handleSubmit={() => handlePeriodChange(3)}
                        selected={selectedPeriod === 3}
                    />
                    <Calendar onSelect={handleDateRange} />
                </div>
            </div>
            <hr className="border-black border-2 mt-2"/>
            <div className="totals-section mt-4 p-4 bg-white shadow-lg rounded-lg grid grid-cols-2 gap-4">
                {/* Completed Totals */}
                <div className="completed-totals">
                    <h3 className="text-lg font-semibold text-gray-800">
                        <FontAwesomeIcon icon={faChartSimple} className="mr-2" />
                        Completed
                    </h3>
                    <p className="total-win">
                        <strong>Total Risked:</strong> <b className={` ${resultColorTotal(totalRiskedCompleted)}`}>{formatNumber(totalRiskedCompleted, 0)} </b>
                    </p>
                    <p className="total-win">
                        <strong>Total Won:</strong> <b className={` ${resultColorTotal(totalWonCompleted)}`}>{formatNumber(totalWonCompleted, 0)} </b>
                    </p>
                    <p className="total-win">
                        <strong>Total Result:</strong> <b className={` ${resultColorTotal(totalResult)}`}>{formatNumber(totalResult, 0)} </b>
                    </p>
                </div>

                {/* Pending Totals */}
                <div className="pending-totals">
                    <h3 className="text-lg font-semibold text-gray-800">
                        <FontAwesomeIcon icon={faHourglassHalf} className="mr-2" />
                        Pending
                    </h3>
                    <p className="total-risk">
                        <strong>Total Risked:</strong> <b className={` ${resultColorTotal(totalRiskedPending)}`}>{formatNumber(totalRiskedPending,0)} </b>
                    </p>
                    <p className="total-win">
                        <strong>Potential Won:</strong> <b className={` ${resultColorTotal(totalWonPending)}`}>{formatNumber(totalWonPending, 0)} </b>
                    </p>
                </div>
            </div>
            <hr className="border-black border-2 mt-2" />
            {!isLoading ? (
                <>
                    <table className="table-auto w-full border-collapse mt-4 text-sm">
                        <thead>
                        <tr>
                            <th>Bet ID</th>
                            <th>Channel ID</th>
                            <th>Channel Name</th>
                            <th>Added By</th>
                            <th>Play</th>
                            <th>Date</th>
                            <th>Odds</th>
                            <th>Risk</th>
                            <th>Win</th>
                            <th>Outcome</th>
                            <th>Result</th>
                            <th>Telegram</th>
                            <th>Signal</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {memofigures?.data
                            ?.sort((a, b) => b.id - a.id) // Sort numerically in descending order
                            .map((item, index) => {
                            const isHighlighted = isNew && highlightLatest++ === 0 ;
                            return (
                                <tr
                                    key={index}
                                    className={`overflow-hidden whitespace-nowrap text-center border border-b-1 border-t-0 border-r-0 border-l-0 ${isHighlighted ? 'highlight' : ''}`}
                                >
                                    <td className="overflow-hidden p-2">
                                        {item?.id}
                                    </td>
                                    <td className="overflow-hidden p-2">{item?.channel.id}</td>
                                    <td className="overflow-hidden p-2">{item?.channel.name}</td>
                                    <td className="overflow-hidden p-2">{item?.creator.name}</td>
                                    <td className="overflow-hidden p-2">{item?.play}</td>
                                    <td className="overflow-hidden p-2">
                                        {moment(item?.date).format(
                                            "YYYY-MM-DD hh:mm A"
                                        )}
                                    </td>
                                    <td className="overflow-hidden p-2">{formatNumber(item?.odds, 0)}</td>
                                    <td className="overflow-hidden p-2">{formatNumber(item?.risk_amount, 0)}</td>
                                    <td className="overflow-hidden p-2">{formatNumber(item?.win_amount, 0)}</td>
                                    <td className="overflow-hidden p-2">
                                        {hasPermission(permissions, item.channel_id, "can_edit_play", isAdmin) ? (

                                            <DropDown
                                                value={item?.outcome}
                                                container_classes={`w-full ${
                                                    item?.outcome === "Win"
                                                        ? "bg-green-100"  // Light green background for Win
                                                        : item?.outcome === "Loss"
                                                        ? "bg-red-100"     // Light red background for Loss
                                                        : item?.outcome === "Push"
                                                            ? "bg-yellow-100"  // Light yellow background for Push
                                                            : !item?.outcome
                                                                ? "bg-gray-100"    // Light gray if not selected
                                                                : "bg-white"
                                                }`}
                                                value_is_key={true}
                                                handleChange={(e) => handleOutcomeChange(e, item.id)}
                                                label=""
                                                name="outcome"
                                                options={
                                                    !item?.outcome
                                                        ? [{ value: 'Select', label: 'Select' }, ...outcomes]
                                                        : outcomes
                                                }
                                            />
                                        ) : item?.outcome == null ? 'Not Finished' : item?.outcome }
                                    </td>
                                    <td
                                        className={`overflow-hidden p-2 font-bold text-right ${resultColor(
                                            item
                                        )}`}
                                    >
                                      {resultLoader.id == item?.id && resultLoader.loading ? (
                                          <Loader />
                                      ) : (
                                          formatResult(item)
                                      )}
                                    </td>
                                    {/*<td className="overflow-hidden p-2">{item?.notes}</td>*/}
                                    <td className="overflow-hidden p-2">
                                        {item?.posted_to_telegram ? (
                                            <span style={{color: 'green'}}>✅</span> // Green Tick for true
                                        ) : (
                                            <span style={{color: 'red'}}>❌</span>  // Red Cross for false
                                        )}
                                    </td>
                                    <td className="overflow-hidden p-2">
                                        {item?.posted_to_signal ? (
                                            <span style={{color: 'green'}}>✅</span> // Green Tick for true
                                        ) : (
                                            <span style={{color: 'red'}}>❌</span>  // Red Cross for false
                                        )}
                                    </td>
                                    <td className="overflow-hidden p-2">
                                        <Link
                                            to={`/auth/dashboard/single-play/${item.id}`}
                                            type="button"
                                            className="inline-flex items-center mr-2  rounded-md bg-blue-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        >
                                            <FontAwesomeIcon icon={faEye}/>
                                        </Link>
                                        {hasPermission(permissions, item.channel_id, "can_edit_play", isAdmin) ? (

                                                    <Link
                                                    to={`/auth/dashboard/edit-play/${item.id}`}
                                                    type="button"
                                                    className="inline-flex  mr-2 items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                >
                                            <FontAwesomeIcon icon={faPenToSquare}/>
                                        </Link>
                                        ) : null}
                                        {hasPermission(permissions, item.channel_id, "can_delete_play", isAdmin) ? (
                                            <Link
                                                onClick={() => handleDelete(item.id)}
                                                type="button"
                                                className="inline-flex  mr-2 items-center gap-x-1.5 rounded-md bg-red-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                            >
                                                <FontAwesomeIcon icon={faTrash}/>
                                            </Link>
                                        ) : null}
                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </>
            ) : (
                <table className="table-auto  rounded w-full border-collapse mt-8 text-sm">
                    <tbody>
                    <tr className="rounded-md animate-pulse border border-1 border-b-black h-10 bg-gray-300">
                        <td className="rounded-md"></td>
                    </tr>
                    <tr className="rounded-md border border-1 border-b-black h-10 bg-gray-200">
                        <td className="rounded-md"></td>
                    </tr>
                    <tr className="rounded-md animate-pulse border border-1 border-b-black h-10 bg-gray-300">
                        <td className="rounded-md"></td>
                    </tr>
                    <tr className="rounded-md border border-1 border-b-black h-10 bg-gray-200">
                        <td className="rounded-md"></td>
                    </tr>
                    <tr className="rounded-md animate-pulse border border-1 border-b-black h-10 bg-gray-300">
                        <td className="rounded-md"></td>
                    </tr>
                    <tr className="rounded-md border border-1 border-b-black h-10 bg-gray-200">
                        <td className="rounded-md"></td>
                    </tr>
                    <tr className="rounded-md animate-pulse border border-1 border-b-black h-10 bg-gray-300">
                        <td className="rounded-md"></td>
                    </tr>
                    <tr className="rounded-md border border-1 border-b-black h-10 bg-gray-200">
                        <td className="rounded-md"></td>
                    </tr>
                    <tr className="rounded-md animate-pulse border border-1 border-b-black h-10 bg-gray-300">
                        <td className="rounded-md"></td>
                    </tr>
                    <tr className="rounded-md border border-1 border-b-black h-10 bg-gray-200">
                        <td className="rounded-md"></td>
                    </tr>
                    <tr className="rounded-md animate-pulse border border-1 border-b-black h-10 bg-gray-300">
                        <td className="rounded-md"></td>
                    </tr>
                    </tbody>
                </table>
            )}
        </div>
    );
}


