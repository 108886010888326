import axios from "axios";
import http from "axios";

const apiUrl = process.env.REACT_APP_API_URL;
const apiTokenUrls = process.env.REACT_APP_API_TOKEN_URL;

// Set up the Axios instance
axios.defaults.baseURL = apiUrl;
axios.defaults.withCredentials = true;
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

// Function to get the token from localStorage
const getToken = () => localStorage.getItem("token"); // Adjust this based on your token storage

// Add a request interceptor to include the Bearer token
axios.interceptors.request.use(config => {
  const token = getToken();
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`; // Add the Bearer token
  }
  return config;
}, error => {
  return Promise.reject(error);
});

// CSRF Token Setup
let token = localStorage.getItem("XSRF-TOKEN");
if (token) {
  axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
} else {
  // Uncomment this if you want to fetch the CSRF token dynamically
  // getCsrf();
}

export const getCsrf = (__) => {
  http.get(apiTokenUrls, { withCredentials: true }).then((res) => {});
};

/*
|======================================================
| Authentication
|======================================================
*/
export const login = (data) => axios.post("/login", data).then(response => {
  // Save the token after a successful login
  if (response.data.token) {
    localStorage.setItem("token", response.data.token); // Adjust according to your response structure
  }
  return response;
});

/*
|======================================================
| Channel
|======================================================
*/
export const getChannels = (filter = {}, type) => {
  return axios.get("/channels", {
    params: filter,
  });
};

export const getChannelById = (id = {}) => {
  return axios.get(`/channels/${id}`);
};

export const saveChannel = (data) => {
  return axios.post(`/channels`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const updateChannel = (id, data) => {
  return axios.put(`/channels/${id}`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

/*
|======================================================
| Plays
|======================================================
*/
export const getPlays = (filter = {}, type) => {
  return axios.get("/plays", {
    params: filter,
  });
};

export const getPlayById = (id = {}) => {
  return axios.get(`/plays/${id}`);
};

export const exportPlays = (filter = {}, type) => {
  return axios.get("/export/plays", {
    params: filter,
  });
};

export const savePlay = (data) => {
  return axios.post(`/plays`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const updatePlay = (id, data) => {
  return axios.put(`/plays/${id}`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const deletePlay = (id) => {
  return axios.delete(`/plays/${id}`);
};


/*
|======================================================
| Users
|======================================================
*/
export const getUsers = (filter = {}, type) => {
  return axios.get("/users", {
    params: filter,
  });
};

export const getUserById = (id = {}) => {
  return axios.get(`/users/${id}`);
};

export const saveUser = (data) => {
  return axios.post(`/users`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const updateUser = (id, data) => {
  return axios.put(`/users/${id}`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const deleteUser = (id) => {
  return axios.delete(`/users/${id}`);
};
/*
|======================================================
| Permissions
|======================================================
*/
export const fetchUserPermissions = (id = {}) => {
  return axios.get(`/users/get/permissions`);
};
