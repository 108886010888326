import { createSlice } from "@reduxjs/toolkit";
import moment from 'moment'

export const searchSlice  = createSlice({
    name:'search-filter',
    initialState:{
        channel_id:null,
        start_date:moment().format("YYYY-MM-DD"),
        end_date:moment().format("YYYY-MM-DD"),
    },
    reducers:{
        searchAction:(state,action) => {
            state.channel_id = action.payload.channel_id ?? state.channel_id;
            state.start_date = action.payload.start_date ?? state.start_date;
            state.end_date = action.payload.end_date ?? state.end_date;
        }
    }
});

export const { searchAction } = searchSlice.actions;

export default searchSlice.reducer