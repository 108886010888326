import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {getChannels, getPlayById, updatePlay} from "../../../routes/api";
import { toast } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage, useFormikContext } from 'formik';
import * as Yup from "yup";

export default function EditPlay() {
    const params = useParams();
    const navigate = useNavigate();
    const [channels, setChannels] = useState([]);

    const [initialValues, setInitialValues] = useState({
        id: '',
        play: '',
        date: '',
        odds: '',
        risk_amount: '',
        win_amount: '',
        channel_id: '',
        outcome: 'Select', // Default value for outcome
    });

    const outcomes = [
        { value: 'Win', label: 'Win' },
        { value: 'Loss', label: 'Loss' },
        { value: 'Push', label: 'Push' },
    ];

    const validationSchema = Yup.object({
        play: Yup.string().required("Play is required"),
        date: Yup.string().required("Date is required"),
        odds: Yup.number().required("Odds are required"),
        risk_amount: Yup.number().required("Risk is required"),
        win_amount: Yup.number().required("Win is required"),
        channel_id: Yup.number().required("Channel is required"),
    });

    const onSubmit = (values, { setSubmitting, resetForm }) => {
        updatePlay(params.id, values)
            .then((res) => {
                if (res.data.status === "success") {
                    toast.success(res.data.message);
                    resetForm();
                    navigate("/auth/dashboard/plays", { replace: true });
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 422) {
                    const validationErrors = error.response.data.errors;
                    for (const [field, messages] of Object.entries(validationErrors)) {
                        messages.forEach((message) => toast.error(`${message}`));
                    }
                } else {
                    toast.error('An error occurred. Please try again.');
                }
            });
        setSubmitting(false);
    };

    useEffect(() => {
        const fetchPlay = async () => {
            try {
                const resChannels = await getChannels();
                setChannels(resChannels?.data?.data?.data);

                const res = await getPlayById(params.id);
                const data = res.data.data;
                const formattedDate = data.date; // Take only the date part
                console.log(formattedDate)

                setInitialValues({
                    id: data.id,
                    play: data.play,
                    date: formattedDate,
                    odds: data.odds,
                    risk_amount: data.risk_amount,
                    win_amount: data.win_amount,
                    channel_id: data.channel_id,
                    outcome: data.outcome, // Resetting outcome if needed
                });
            } catch (error) {
                console.error('Error fetching play:', error);
            }
        };

        fetchPlay();
    }, [params.id]);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize // Allow Formik to reinitialize when initialValues change
        >
            {({ values }) => (
                <Form>
                    <h1 className="font-bold text-xl">Edit Play {initialValues.id}</h1>
                    <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                        <div className="grid grid-cols-3 gap-4 mt-4">

                            <div className="col-span-1">
                                <label htmlFor="channel_id" className="form-label required">Channel</label>
                                <Field as="select" name="channel_id" className="form-control">
                                    <option value="">Select</option>
                                    {channels.map(option => (
                                        <option key={option.id} value={option.id}>
                                            {option.name}
                                        </option>
                                    ))}
                                </Field>
                                <ErrorMessage name="channel_id" component="div" className="form-error" />
                            </div>

                            <div className="col-span-1">
                                <label htmlFor="play" className="form-label required">Play</label>
                                <Field type="text" name="play" id="play" className="form-control" />
                                <ErrorMessage name="play" component="div" className="form-error" />
                            </div>
                            <div className="col-span-1">
                                <label htmlFor="date" className="form-label required">Date</label>
                                <Field type="datetime-local" name="date" id="date" className="form-control" />
                                <ErrorMessage name="date" component="div" className="form-error" />
                            </div>
                            <div className="col-span-1">
                                <label htmlFor="odds" className="form-label required">Odds</label>
                                <Field type="number" name="odds" id="odds" className="form-control" />
                                <ErrorMessage name="odds" component="div" className="form-error" />
                            </div>
                            <div className="col-span-1">
                                <label htmlFor="risk_amount" className="form-label required">Risk Amount</label>
                                <Field type="number" name="risk_amount" id="risk_amount" className="form-control" />
                                <ErrorMessage name="risk_amount" component="div" className="form-error" />
                            </div>
                            <div className="col-span-1">
                                <label htmlFor="outcome" className="form-label">Outcome</label>
                                <Field as="select" name="outcome" className="form-control">
                                    <option value="">Select</option>
                                    {outcomes.map(option => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </Field>
                                <ErrorMessage name="outcome" component="div" className="form-error" />
                            </div>
                            <WinAmountCalculator />
                        </div>
                        <div className="flex items-center justify-start gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
                            <button
                                type="submit"
                                className={'rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 bg-indigo-600 hover:bg-indigo-500'}
                            >
                                Save
                            </button>
                            <button
                                type="button"
                                className="text-sm font-semibold text-gray-900"
                                onClick={() => navigate("/auth/dashboard/plays")}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
}

// New component for calculating the win amount
const WinAmountCalculator = () => {
    const { values, setFieldValue } = useFormikContext();

    useEffect(() => {
        if (values.odds && values.risk_amount) {
            const odds = parseFloat(values.odds);
            const riskAmount = parseFloat(values.risk_amount);

            if (!isNaN(odds) && !isNaN(riskAmount)) {
                const decimalOdds = odds < 0 ? (100 / Math.abs(odds)) : (odds / 100);
                const winAmount = parseFloat((riskAmount * decimalOdds).toFixed(2));
                setFieldValue('win_amount', winAmount);
            } else {
                setFieldValue('win_amount', '');
            }
        }
    }, [values.odds, values.risk_amount, setFieldValue]);

    return (
        <div className="col-span-1">
            <label htmlFor="win_amount" className="form-label required">Win Amount</label>
            <Field type="number" name="win_amount" id="win_amount" className="form-control" readOnly />
            <ErrorMessage name="win_amount" component="div" className="form-error" />
        </div>
    );
};
