import { configureStore } from "@reduxjs/toolkit";
import search from "./slices/search";
import auth from "./slices/auth";
import permissions from "./slices/permissions"; // Import the permissions slice

const store = configureStore({
  reducer: {
    search: search,
    auth: auth,
    permissions: permissions, // Add permissions to the store
  },
});

export default store;
