import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {getChannels, getUserById, updateUser} from "../../../routes/api";
import { toast } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";

export default function EditUser() {
  const params = useParams();
  const navigate = useNavigate();

  const [initialValues, setInitialValues] = useState({
    name: '',
    email: '',
    new_password: '',
    permissions: {}
  });

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string().required("Email is required"),
  });

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    updateUser(params.id, values)
        .then((res) => {
          if (res.data.status === "success") {
            toast.success(res.data.message);
            resetForm();
            navigate("/auth/dashboard/users", { replace: true });
          }
        })
        .catch((error) => {
          console.log(error)
          if (error.response && error.response.status === 422) {
            const validationErrors = error.response.data.errors;
            for (const [field, messages] of Object.entries(validationErrors)) {
              messages.forEach((message) => toast.error(`${message}`));
            }
          } else {
            toast.error(error.response.data.message);
          }
        });
    setSubmitting(false);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userRes = await getUserById(params.id); // Fetch user data with permissions
        const channelsRes = await getChannels();   // Fetch all channels

        const userData = userRes.data.data;
        const allChannels = channelsRes.data.data.data;

        const permissions = {};

        // Loop through all channels and set permissions accordingly
        allChannels.forEach(channel => {
          const userPermission = userData.permissions.find(
              permission => permission.channel_id === channel.id
          );

          permissions[channel.id] = {
            id: channel.id,
            name: channel.name,
            can_create_play: userPermission ? userPermission.can_create_play : 0,
            can_edit_play: userPermission ? userPermission.can_edit_play : 0,
            can_delete_play: userPermission ? userPermission.can_delete_play : 0,
            can_view_play: userPermission ? userPermission.can_view_play : 0,
          };
        });

        setInitialValues({
          id: userData.id,
          name: userData.name,
          email: userData.email,
          permissions
        });
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, [params.id]);

  return (
      <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
      >
        {({ values, setFieldValue }) => (
            <Form autoComplete="off">
              <h1 className="font-bold text-xl">Edit User {initialValues.id}</h1>
              <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                <div className="grid grid-cols-3 gap-4 mt-4">
                  <div className="col-span-1">
                    <label htmlFor="name" className="form-label required">Name</label>
                    <Field type="text" name="name" id="name" className="form-control" />
                    <ErrorMessage name="name" component="div" className="form-error" />
                  </div>

                  <div className="col-span-1">
                    <label htmlFor="email" className="form-label required">Email</label>
                    <Field type="text" name="email" id="email" className="form-control" />
                    <ErrorMessage name="email" component="div" className="form-error" />
                  </div>

                  <div className="col-span-1">
                    <label htmlFor="new_password" className="form-label">New Password (optional)</label>
                    <Field
                        type="password"
                        name="new_password"
                        id="new_password"
                        className="form-control"
                        autoComplete="new-password"
                    />
                    <ErrorMessage name="new_password" component="div" className="form-error" />
                  </div>
                </div>

                <h2 className="font-bold text-lg mt-4">Play Permissions</h2>
                <div className="grid grid-cols-1 gap-4 mt-2">
                  {Object.entries(values.permissions).map(([channelId, perms]) => (
                      <div key={channelId} className="flex items-center justify-between border p-4 rounded-md">
                        <span className="font-semibold">{perms.name}</span>
                        <div className="flex space-x-4">
                          <label className="flex items-center space-x-2">
                            <Field
                                type="checkbox"
                                name={`permissions.${channelId}.can_create_play`}
                                checked={perms.can_create_play}
                                onChange={() => setFieldValue(`permissions.${channelId}.can_create_play`, !perms.can_create_play)}
                            />
                            <span>Can Create</span>
                          </label>
                          <label className="flex items-center space-x-2">
                            <Field
                                type="checkbox"
                                name={`permissions.${channelId}.can_edit_play`}
                                checked={perms.can_edit_play}
                                onChange={() => setFieldValue(`permissions.${channelId}.can_edit_play`, !perms.can_edit_play)}
                            />
                            <span>Can Edit</span>
                          </label>
                          <label className="flex items-center space-x-2">
                            <Field
                                type="checkbox"
                                name={`permissions.${channelId}.can_delete_play`}
                                checked={perms.can_delete_play}
                                onChange={() => setFieldValue(`permissions.${channelId}.can_delete_play`, !perms.can_delete_play)}
                            />
                            <span>Can Delete</span>
                          </label>
                          <label className="flex items-center space-x-2">
                            <Field
                                type="checkbox"
                                name={`permissions.${channelId}.can_view_play`}
                                checked={perms.can_view_play}
                                onChange={() => setFieldValue(`permissions.${channelId}.can_view_play`, !perms.can_view_play)}
                            />
                            <span>Can View</span>
                          </label>
                        </div>
                      </div>
                  ))}
                </div>

                <div className="flex items-center justify-start gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
                  <button
                      type="submit"
                      className="rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 bg-indigo-600 hover:bg-indigo-500"
                  >
                    Save
                  </button>
                  <button
                      type="button"
                      className="text-sm font-semibold text-gray-900"
                      onClick={() => navigate("/auth/dashboard/users")}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Form>
        )}
      </Formik>
  );
}
