import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    token: null,
    isLoggedIn: false,
    isAdmin: false, // Add isAdmin to the initial state
  },
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    setIsAdmin: (state, action) => { // Add a reducer to set isAdmin
      state.isAdmin = action.payload;
    },
  },
});

export const { setToken, setIsLoggedIn, setIsAdmin } = authSlice.actions;

export default authSlice.reducer;
