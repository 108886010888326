import { useEffect, useMemo, useRef, useState } from "react";
import {
  getChannels,
} from "../../../routes/api";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faEye,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { hasPermission } from "../../../utils/permissions";

export default function Channels() {
  const [channels, setChannels] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getLastChannels();
  }, []);

  async function getLastChannels(params) {
    return getChannels({ }).then((res) => {
      const allChannels = res?.data?.data?.data;

      // Filter channels based on `can_create_play` permission or if user is admin
      const filteredChannels = isAdmin
          ? allChannels
          : allChannels.filter(channel =>
              hasPermission(permissions, channel.id, "can_view_play")
          );

      setChannels(filteredChannels);

      setIsLoading(false);
      return true;
    });
  }

  const memoChannels = useMemo(() => channels, [channels]);

  const permissions = useSelector((state) => state.permissions);
  const isAdmin = useSelector((state) => state.auth.isAdmin);

  return (
      <div>
        {!isLoading ? (
            <>
              {(isAdmin) && (
                <div className="flex justify-between items-center mb-4">
                  <h1 className="text-xl font-bold"></h1>
                  <Link
                      to="/auth/dashboard/add-channel" // Update the path to your add user page
                      className="inline-flex items-center gap-x-1.5 rounded-md bg-green-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                  >
                    <FontAwesomeIcon icon={faPlus} /> Add Channel
                  </Link>
                </div>
              )}
              <table className="table-auto w-full border-collapse mt-4 text-sm">
                <thead>
                <tr>
                  <th>Channel ID</th>
                  <th>Name</th>
                  <th>Telegram Chat ID</th>
                  <th>Signal ID</th>
                </tr>
                </thead>
                <tbody>
                {memoChannels?.map((item, index) => {
                  return (
                      <tr
                          key={index}
                          className="overflow-hidden whitespace-nowrap text-center border border-b-1 border-t-0 border-r-0 border-l-0"
                      >
                        <td className="overflow-hidden p-2">
                          {item?.id}
                        </td>
                        <td className="overflow-hidden p-2">{item?.name}</td>
                        <td className="overflow-hidden p-2">{item?.telegram_chat_id}</td>
                        <td className="overflow-hidden p-2">{item?.signal_id}</td>
                        <td className="overflow-hidden p-2 text-sm">
                          {item?.sport}
                        </td>
                        <td className="overflow-hidden p-2">
                          <Link
                              to={`/auth/dashboard/single-channel/${item.id}`}
                              type="button"
                              className="inline-flex items-center mr-2  rounded-md bg-blue-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          >
                            <FontAwesomeIcon icon={faEye} />
                          </Link>
                          {hasPermission(permissions, item.id, "can_edit_play", isAdmin) ? (
                              <Link
                                  to={`/auth/dashboard/edit-channel/${item.id}`}
                                  type="button"
                                  className="inline-flex mr-2 items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                              >
                                <FontAwesomeIcon icon={faPenToSquare} />
                              </Link>
                          ) : null}
                        </td>
                      </tr>
                  );
                })}
                </tbody>
              </table>
            </>
        ) : (
            <table className="table-auto  rounded w-full border-collapse mt-8 text-sm">
              <tbody>
              <tr className="rounded-md animate-pulse border border-1 border-b-black h-10 bg-gray-300">
                <td className="rounded-md"></td>
              </tr>
              <tr className="rounded-md border border-1 border-b-black h-10 bg-gray-200">
                <td className="rounded-md"></td>
              </tr>
              <tr className="rounded-md animate-pulse border border-1 border-b-black h-10 bg-gray-300">
                <td className="rounded-md"></td>
              </tr>
              <tr className="rounded-md border border-1 border-b-black h-10 bg-gray-200">
                <td className="rounded-md"></td>
              </tr>
              <tr className="rounded-md animate-pulse border border-1 border-b-black h-10 bg-gray-300">
                <td className="rounded-md"></td>
              </tr>
              <tr className="rounded-md border border-1 border-b-black h-10 bg-gray-200">
                <td className="rounded-md"></td>
              </tr>
              <tr className="rounded-md animate-pulse border border-1 border-b-black h-10 bg-gray-300">
                <td className="rounded-md"></td>
              </tr>
              <tr className="rounded-md border border-1 border-b-black h-10 bg-gray-200">
                <td className="rounded-md"></td>
              </tr>
              <tr className="rounded-md animate-pulse border border-1 border-b-black h-10 bg-gray-300">
                <td className="rounded-md"></td>
              </tr>
              <tr className="rounded-md border border-1 border-b-black h-10 bg-gray-200">
                <td className="rounded-md"></td>
              </tr>
              <tr className="rounded-md animate-pulse border border-1 border-b-black h-10 bg-gray-300">
                <td className="rounded-md"></td>
              </tr>
              </tbody>
            </table>
        )}
      </div>
  );
}
