import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

export default function Sidebar(props) {
    const appName = process.env.REACT_APP_NAME;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const history = useLocation();
    const path =
        history.pathname.split("/")[history.pathname.split("/").length - 1];

    return (
        <motion.div
            animate={{
                width: props.isExpanded ? "180px" : "50px",
                transition: { ease: "easeInOut", duration: 0.5 },
            }}
            className={`bg-primary  overflow-hidden text-white h-full min-h-screen p-4`}
        >
            <div className="flex justify-between items-center w-full">
                <motion.h1
                    animate={{
                        opacity: props.isExpanded ? "1" : "0",
                        transition: { ease: "easeInOut", duration: 2, delay: 0.1 },
                        transitionEnd: { display: props.isExpanded ? "block" : "none" },
                    }}
                >
                    <svg onClick={() => navigate("/auth/dashboard/plays")} className="logo-text cursor-pointer" viewBox="0 0 480 110">
                        <text y="50">{appName}</text>
                    </svg>
                </motion.h1>
            </div>
            <ul className="mt-8">
                {props.options && props.options.length > 0 ? (
                    props.options.map((option, index) => {
                        return (
                            <div
                                className={` ${
                                    path.includes(option.url) ? "bg-white text-black font-bold" : ""
                                }`}
                                onClick={() =>
                                    option.action ? option.action(dispatch) : navigate(option.url)
                                }
                                key={index}
                            >
                                <li
                                    className="flex justify-between items-center gap-1 cursor-pointer hover:bg-blue-800 hover:text-white p-1"
                                >
                                    <FontAwesomeIcon
                                        icon={option.icon}
                                        size={`${props.isExpanded ? "lg" : "xl"}`}
                                    />
                                    <p className="text-left">{option.title}</p>
                                </li>
                                <hr
                                    className={`  ${
                                        props.isExpanded ? "border-gray-400" : "border-primary"
                                    }`}
                                />
                            </div>
                        );
                    })
                ) : (
                    <li className="text-center">No options available</li> // Fallback if no options
                )}
            </ul>
        </motion.div>
    );
}
