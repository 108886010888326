import React, { Ref, forwardRef } from "react";
import { twMerge } from "tailwind-merge";

const Input = forwardRef((props, ref) => {
  const { type = "text" } = props;
  return (
    <>
      <input
        type={type}
        ref={ref}
        placeholder={props.placeholder}
        value={props.value}
        className={twMerge(
          "border rounded w-full p-2",
          props.errors ? "border-red-400" : "border-gray-400"
        )}
      />
      {props.errors && <span className="text-red-400">{props.errors}</span>}
    </>
  );
});

export default Input;
