import Input from "../components/form/Input";
import { ReactComponent as LoginIcon } from "../assets/icons/login.svg";
import Button from "../components/form/Button";
import { useNavigate } from "react-router-dom";
import { createRef, useEffect, useState } from "react";
import { validateEmail } from "../utils/helpers";
import {fetchUserPermissions, login} from "../routes/api";
import { useDispatch, useSelector } from "react-redux";
import { setIsLoggedIn, setToken, setIsAdmin } from "../redux/slices/auth";
import {setPermissions} from "../redux/slices/permissions"; // Import setIsAdmin

export default function Login() {
  const { isLoggedIn } = useSelector((state) => state.auth);
  const [errors, setErrors] = useState({
    general: null,
    email: null,
    password: null,
  });
  const [isSubmit, setIsSubmit] = useState(false);
  const email = createRef();
  const password = createRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoggedIn) {
      async function getPermissions() {
        const permissions = await fetchUserPermissions();
        dispatch(setPermissions(permissions?.data?.data));
      }

      getPermissions();

      navigate("/auth/dashboard/plays", { replace: true });
    }
  }, [isLoggedIn, navigate]); // Add isLoggedIn to dependencies

  const handleSubmit = () => {
    let error = errors;
    let is_error = false;
    if (email.current?.value === "") {
      error = { ...error, email: "Email field is required" };
      is_error = true;
    } else if (validateEmail(email.current?.value) == null) {
      error = { ...error, email: "Please enter a correct email" };
      is_error = true;
    }

    if (password.current?.value === "") {
      error = { ...error, password: "Password field is required" };
      is_error = true;
    }

    if (is_error) {
      setErrors(error);
      return;
    }
    setIsSubmit(true);
    login({
      email: email.current?.value,
      password: password.current?.value,
    }).then((res) => {
      if (res.data?.status === "error") {
        error = { ...error, general: res.data?.message };
        setErrors(error);
        setIsSubmit(false);
      } else {
        const { token, isAdmin } = res.data?.data; // Assume the API response includes isAdmin
        localStorage.setItem("token", token);
        localStorage.setItem("isLoggedIn", true);
        localStorage.setItem("isAdmin", isAdmin ? "true" : "false");

        dispatch(setToken(token));
        dispatch(setIsLoggedIn(true));
        dispatch(setIsAdmin(isAdmin)); // Dispatch the isAdmin value
        navigate("/auth/dashboard/plays", { replace: true });
      }
    });
  };

  return (
      <div className="grid grid-cols-6">
        <div className="col-span-2 flex flex-col gap-4 justify-center items-center">
          <h4 className="text-4xl">Welcome Back</h4>
          {errors.general && (
              <span className="text-red-500">{errors.general}</span>
          )}
          <div className="w-8/12">
            <Input
                ref={email}
                errors={errors.email}
                placeholder="Enter Email"
                label="Email"
                type="email"
            />
          </div>
          <div className="w-8/12">
            <Input
                ref={password}
                errors={errors.password}
                placeholder="Enter Password"
                type="password"
                label="Password" // Update label to 'Password'
            />
          </div>
          <div className="w-8/12">
            <Button
                innerText="Login"
                classes="bg-green-400 text-white"
                handleSubmit={handleSubmit}
                isLoading={isSubmit}
            />
          </div>
        </div>
        <div className="flex justify-center col-span-4 bg-primary h-screen">
          <LoginIcon className="w-8/12" />
        </div>
      </div>
  );
}
