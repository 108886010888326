import { useDispatch } from "react-redux";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { setIsLoggedIn, setToken } from "../redux/slices/auth";

export default function Authentication(props) {
  const dispatch = useDispatch();

  const isLoggedIn = localStorage.getItem("isLoggedIn");
  const location = useLocation();
  if (isLoggedIn) {
    const token = localStorage.getItem("token");
    dispatch(setToken(token));
    dispatch(setIsLoggedIn(true));
  }

  return (
    <>
      {isLoggedIn && isLoggedIn != "undefined" ? (
        <Outlet />
      ) : (
        <Navigate to="/login" state={{ from: location }} replace={true} />
      )}
    </>
  );
}
