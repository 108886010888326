import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { toast } from 'react-toastify';
import { saveUser, getChannels } from "../../../routes/api";
import { useNavigate } from "react-router-dom";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function AddUser() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false); // State for toggle
  const [channels, setChannels] = useState([]);

  useEffect(() => {
    getAllChannels();
  }, []);

  async function getAllChannels() {
    const resChannels = await getChannels();
    setChannels(resChannels?.data?.data?.data);
  }

  const initialValues = {
    name: "",
    email: "",
    password: "",
    permissions: channels.reduce((acc, channel) => {
      acc[channel.id] = {
        channel_id: channel.id,
        canView: false,
        canEdit: false,
        canDelete: false,
        canSave: false,
      };
      return acc;
    }, {}),
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string().required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  // Form submission handler
  const onSubmit = (values, { setSubmitting, resetForm }) => {
    console.log('form: ', values);

    saveUser(values).then((res) => {
      if (res.data.status === "success") {
        toast.success(res.data.message);
        // Clear the form
        resetForm();
        navigate("/auth/dashboard/users", { replace: true });
      }
    }).catch((error) => {
      if (error.response && error.response.status === 422) {
        // Validation errors
        const validationErrors = error.response.data.errors;
        for (const [field, messages] of Object.entries(validationErrors)) {
          messages.forEach(message => toast.error(`${message}`));
        }
      } else {
        // Other errors
        toast.error('An error occurred. Please try again.');
      }
    });

    setSubmitting(false); // Reset isSubmitting to false after submission
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword); // Toggle the state
  };

  return (
      <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
      >
        {({ isSubmitting, setFieldValue }) => (
            <Form>
              <h1 className="font-bold text-xl">Add New User</h1>
              <div className="grid grid-cols-3 gap-4 mt-4">

                <div className="col-span-1">
                  <label htmlFor="name" className="form-label">Name</label>
                  <Field
                      type="text"
                      name="name"
                      id="name"
                      className="form-control"
                  />
                </div>

                <div className="col-span-1">
                  <label htmlFor="email" className="form-label">Email</label>
                  <Field
                      type="email"
                      name="email"
                      id="email"
                      className="form-control"
                  />
                </div>

                <div className="col-span-1">
                  <label htmlFor="password" className="form-label">Password</label>
                  <div className="relative"> {/* Wrap the input and button in a relative div */}
                    <Field
                        type={showPassword ? "text" : "password"} // Change type based on state
                        name="password"
                        id="password"
                        className="form-control pr-10" // Add padding to prevent overlap with icon
                    />
                    <button
                        type="button"
                        onClick={handleTogglePassword}
                        className="absolute inset-y-0 right-0 flex items-center pr-3" // Position the toggle button
                    >
                      {showPassword ? (
                          <FontAwesomeIcon icon={faEyeSlash} /> // Use eye slash icon when showing password
                      ) : (
                          <FontAwesomeIcon icon={faEye} /> // Use eye icon when hiding password
                      )}
                    </button>
                  </div>
                </div>

              </div>

              <h2 className="font-bold text-lg mt-4">Play Permissions</h2>
              <div className="grid grid-cols-1 gap-4 mt-2">
                {channels.map(channel => (
                    <div key={channel.id} className="flex items-center justify-between border p-4 rounded-md">
                      <span className="font-semibold">{channel.name}</span>
                      <div className="flex space-x-4">
                        <label className="flex items-center">
                          <Field type="checkbox" name={`permissions.${channel.id}.can_create_play`} />
                          <span className="ml-2">Can Create</span>
                        </label>
                        <label className="flex items-center">
                          <Field type="checkbox" name={`permissions.${channel.id}.can_edit_play`} />
                          <span className="ml-2">Can Edit</span>
                        </label>
                        <label className="flex items-center">
                          <Field type="checkbox" name={`permissions.${channel.id}.can_delete_play`} />
                          <span className="ml-2">Can Delete</span>
                        </label>
                        <label className="flex items-center">
                          <Field type="checkbox" name={`permissions.${channel.id}.can_view_play`} />
                          <span className="ml-2">Can View</span>
                        </label>
                      </div>
                    </div>
                ))}
              </div>
              <div className="flex items-center justify-start gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
                <button
                    type="submit"
                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Add
                </button>
                <button
                    type="button"
                    className="text-sm font-semibold leading-6 text-gray-900"
                    onClick={() => navigate("/auth/dashboard/users")}
                >
                  Cancel
                </button>
              </div>
            </Form>
        )}
      </Formik>
  );
}
