import React, { useState } from "react";

export default function CalendarButton({ btnText, handleSubmit, selected }) {
  return (
    <button
      disabled={selected}
      onClick={handleSubmit}
      className={`cursor-pointer hover:bg-gray-200 hover:text-primary border border-1 border-gray-500 text-gray-500 
      rounded text-center py-1 px-2 ${selected ? "bg-primary text-white" : ""}`}
    >
      {btnText}
    </button>
  );
}
