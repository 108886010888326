import { createSlice } from "@reduxjs/toolkit";

const permissions = createSlice({
    name: "permissions",
    initialState: [],
    reducers: {
        setPermissions: (state, action) => action.payload,
        clearPermissions: () => [],
    },
});

export const { setPermissions, clearPermissions } = permissions.actions;
export default permissions.reducer;