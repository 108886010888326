export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const getWeeks = () => {
  let weeks = [];
  let temp = {};
  for (let i = 3; i <= 20; i++) {
    temp = {};
    temp.key = i;
    temp.value = `${i +" weeks ago"}`;
    weeks.push(temp);
  }

  return weeks;
};

export const getWeeksForChart = () => {
  let weeks = [];
  let temp = {};
  temp.key = 0;
  temp.value = "This week";
  weeks.push(temp);
  temp = {};
  temp.key = 1;
  temp.value = "Last week";
  weeks.push(temp);

  for (let i = 2; i <= 20; i++) {
    temp = {};
    temp.key = i;
    temp.value = `${i +" weeks ago"}`;
    weeks.push(temp);
  }

  return weeks;
};

export const getOutcomes = () => {
  return [
    { key: "Win", value: "Win" },
    { key: "Loss", value: "Loss" },
    { key: "Push", value: "Push" },
  ];
};

export const formatResult = (item) => {
  return item?.outcome == "Win"
    ? "" + (item.result > 0 ? '+':'') + formatNumber(item.result, 0)
    : item?.outcome == "Loss"
    ? "" + (item.result > 0 ? '+':'') + formatNumber(item.result, 0)
    : "" + parseFloat(0).toFixed(0);
};

export const resultColor = (item) => {
  return item?.outcome == "Win"
    ? "text-green-500"
    : item?.outcome == "Loss"
    ? "text-red-500"
    : item?.outcome == "Push"
    ? "text-yellow-500"
    : "text-gray-500";
};

export const resultColorTotal = (item) => {
  return item>0
    ? "text-green-500"
    : item<0
    ? "text-red-500"
    : "text-gray-500";
};

export const cleanAmount = (value) => {
  // return value.trim().replace(",", "").slice(1);
  if (value == null) {
    return 0; // or return a default value or handle it accordingly
  }
  
  // Your existing logic for cleaning the amount
  const cleanedValue = parseFloat(value.replace(/[^0-9.-]+/g, ""));
  return isNaN(cleanedValue) ? 0 : cleanedValue;
};

export const formatNumber = (number, fraction = 2) => {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: fraction,
    maximumFractionDigits: fraction
  }).format(number);
};