// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.highlight {
    animation: highlight-animation 1s ease-in-out;
}

@keyframes highlight-animation {
    0% {
        background-color: lightgreen;
    }
    100% {
        background-color: transparent;
    }
}


.totals-section {
    background-color: #f8f9fa; /* Light background color */
    padding: 20px; /* Inner spacing */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    margin: 20px 0; /* Space around the section */
    transition: background-color 0.3s; /* Smooth transition */
}

.totals-section:hover {
    background-color: #e9ecef; /* Slightly darker on hover */
}

.total-risk {
    font-size: 1.2rem; /* Larger font size for emphasis */
    font-weight: 500; /* Medium weight */
}

.total-win {
    font-size: 1.2rem; /* Larger font size for emphasis */
    font-weight: 500; /* Medium weight */
}

.bg-green-100 {
    background-color: #d4edda !important;
}
.bg-red-100 {
    background-color: #f8d7da !important;
}
.bg-gray-100 {
    background-color: #f0f0f0 !important;
}
.bg-yellow-100 {
    background-color: #fff3cd !important; /* Light yellow background for Push */
}
`, "",{"version":3,"sources":["webpack://./src/pages/auth-pages/plays/Plays.css"],"names":[],"mappings":";AACA;IACI,6CAA6C;AACjD;;AAEA;IACI;QACI,4BAA4B;IAChC;IACA;QACI,6BAA6B;IACjC;AACJ;;;AAGA;IACI,yBAAyB,EAAE,2BAA2B;IACtD,aAAa,EAAE,kBAAkB;IACjC,kBAAkB,EAAE,oBAAoB;IACxC,wCAAwC,EAAE,kBAAkB;IAC5D,cAAc,EAAE,6BAA6B;IAC7C,iCAAiC,EAAE,sBAAsB;AAC7D;;AAEA;IACI,yBAAyB,EAAE,6BAA6B;AAC5D;;AAEA;IACI,iBAAiB,EAAE,kCAAkC;IACrD,gBAAgB,EAAE,kBAAkB;AACxC;;AAEA;IACI,iBAAiB,EAAE,kCAAkC;IACrD,gBAAgB,EAAE,kBAAkB;AACxC;;AAEA;IACI,oCAAoC;AACxC;AACA;IACI,oCAAoC;AACxC;AACA;IACI,oCAAoC;AACxC;AACA;IACI,oCAAoC,EAAE,qCAAqC;AAC/E","sourcesContent":["\n.highlight {\n    animation: highlight-animation 1s ease-in-out;\n}\n\n@keyframes highlight-animation {\n    0% {\n        background-color: lightgreen;\n    }\n    100% {\n        background-color: transparent;\n    }\n}\n\n\n.totals-section {\n    background-color: #f8f9fa; /* Light background color */\n    padding: 20px; /* Inner spacing */\n    border-radius: 8px; /* Rounded corners */\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */\n    margin: 20px 0; /* Space around the section */\n    transition: background-color 0.3s; /* Smooth transition */\n}\n\n.totals-section:hover {\n    background-color: #e9ecef; /* Slightly darker on hover */\n}\n\n.total-risk {\n    font-size: 1.2rem; /* Larger font size for emphasis */\n    font-weight: 500; /* Medium weight */\n}\n\n.total-win {\n    font-size: 1.2rem; /* Larger font size for emphasis */\n    font-weight: 500; /* Medium weight */\n}\n\n.bg-green-100 {\n    background-color: #d4edda !important;\n}\n.bg-red-100 {\n    background-color: #f8d7da !important;\n}\n.bg-gray-100 {\n    background-color: #f0f0f0 !important;\n}\n.bg-yellow-100 {\n    background-color: #fff3cd !important; /* Light yellow background for Push */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
