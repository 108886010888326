import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage, useFormikContext } from 'formik';
import { toast } from 'react-toastify';
import { getChannels, savePlay } from "../../../routes/api";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { hasPermission } from "../../../utils/permissions";


export default function AddPlay() {
  const [channels, setChannels] = useState([]);
  const navigate = useNavigate();

  // Get current date and time in EST
  const getCurrentESTDateTime = () => {
    const today = new Date();
    const options = { timeZone: "America/New_York", hour12: false };

    // Get the date in the format YYYY-MM-DD
    const estDate = new Intl.DateTimeFormat('en-CA', {
      ...options,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    }).format(today);

    // Get the time in the format HH:MM
    const estTime = new Intl.DateTimeFormat('en-CA', {
      ...options,
      hour: '2-digit',
      minute: '2-digit'
    }).format(today);

    // Split date and time to ensure proper formatting
    const [day] = estDate.split('/'); // Convert to YYYY-MM-DD
    const [hour, minute] = estTime.split(':'); // Split time into hour and minute

    // Format the date to YYYY-MM-DDTHH:MM
    return `${day}T${hour}:${minute}`;
  };

  let formattedDateTime = getCurrentESTDateTime(); // Get formatted date and time in EST

  const permissions = useSelector((state) => state.permissions);
  const isAdmin = useSelector((state) => state.auth.isAdmin);

  useEffect(() => {
    getChannels().then((res) => {
      const allChannels = res?.data?.data?.data;

      // Filter channels based on `can_create_play` permission or if user is admin
      const filteredChannels = isAdmin
          ? allChannels
          : allChannels.filter(channel =>
              hasPermission(permissions, channel.id, "can_create_play")
          );

      setChannels(filteredChannels);
    });
  }, [permissions, isAdmin]);

  const initialValues = {
    play: "",
    date: formattedDateTime, // Set default datetime based on EST
    odds: "",
    risk_amount: "",
    win_amount: "",
    channel_id: "",
    channels: channels
  };

  const validationSchema = Yup.object({
    play: Yup.string().required("Play is required"),
    date: Yup.string().required("Date is required"),
    odds: Yup.number().required("Odds are required"),
    risk_amount: Yup.number().required("Risk is required"),
    win_amount: Yup.number().required("Win is required"),
  });

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    savePlay(values)
        .then((res) => {
          if (res.data.status === "success") {
            toast.success(res.data.message);
            resetForm();
            navigate("/auth/dashboard/plays?new=true", { replace: true });
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 422) {
            const validationErrors = error.response.data.errors;
            for (const [field, messages] of Object.entries(validationErrors)) {
              messages.forEach((message) => toast.error(`${message}`));
            }
          } else {
            toast.error('An error occurred. Please try again.');
          }
        })
        .finally(() => {
          setSubmitting(false); // Ensure button is re-enabled after response
        });
  };

  return (
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ isSubmitting }) => (
            <Form>
              <h1 className="font-bold text-xl">Add New Play</h1>
              <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                <div className="grid grid-cols-3 gap-4 mt-4">

                  <div className="col-span-1">
                    <label htmlFor="channel_id" className="form-label required">Channel</label>
                    <Field as="select" name="channel_id" className="form-control">
                      <option value="">Select</option>
                      {channels.map(option => (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                      ))}
                    </Field>
                    <ErrorMessage name="channel_id" component="div" className="form-error" />
                  </div>

                  <div className="col-span-1">
                    <label htmlFor="play" className="form-label required">Play</label>
                    <Field type="text" name="play" id="play" className="form-control" />
                    <ErrorMessage name="play" component="div" className="form-error" />
                  </div>

                  <div className="col-span-1">
                    <label htmlFor="date" className="form-label required">Date</label>
                    <Field type="datetime-local" name="date" id="date" className="form-control" value={formattedDateTime}/>
                    <ErrorMessage name="date" component="div" className="form-error" />
                  </div>

                  <div className="col-span-1">
                    <label htmlFor="odds" className="form-label required">Odds</label>
                    <Field type="number" name="odds" id="odds" className="form-control" />
                    <ErrorMessage name="odds" component="div" className="form-error" />
                  </div>

                  <div className="col-span-1">
                    <label htmlFor="risk_amount" className="form-label required">Risk Amount</label>
                    <Field type="number" name="risk_amount" id="risk_amount" className="form-control" />
                    <ErrorMessage name="risk_amount" component="div" className="form-error" />
                  </div>

                  <WinAmountCalculator />

                  {/* Outcome and other fields */}
                </div>
                <div className="flex items-center justify-start gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
                  <button
                      type="submit"
                      className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      disabled={isSubmitting} // Disable while submitting
                  >
                    Add
                  </button>
                  <button
                      type="button"
                      className="text-sm font-semibold leading-6 text-gray-900"
                      onClick={() => navigate("/auth/dashboard/plays")}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Form>
        )}
      </Formik>
  );
}

// New component for calculating the win amount
const WinAmountCalculator = () => {
  const { values, setFieldValue } = useFormikContext(); // Now inside a Formik context

  useEffect(() => {
    if (values.odds && values.risk_amount) {
      const odds = parseFloat(values.odds);
      const riskAmount = parseFloat(values.risk_amount);

      if (!isNaN(odds) && !isNaN(riskAmount)) {
        const decimalOdds = odds < 0 ? (100 / Math.abs(odds)) : (odds / 100);
        const winAmount = parseFloat((riskAmount * decimalOdds).toFixed(2));
        setFieldValue('win_amount', winAmount);
      } else {
        setFieldValue('win_amount', '');
      }
    }
  }, [values.odds, values.risk_amount, setFieldValue]);

  return (
      <div className="col-span-1">
        <label htmlFor="win_amount" className="form-label required">Win Amount</label>
        <Field type="number" name="win_amount" id="win_amount" className="form-control" readOnly />
        <ErrorMessage name="win_amount" component="div" className="form-error" />
      </div>
  );
};
