import { useEffect, useState } from "react";

export const DropDown = (props) => {
  const [value, setValue] = useState(props.value);
  const [loading, isLoading] = useState(false);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const handleValue = async (e) => {
    isLoading(true);
    const isResolved = await props.handleChange(e);
    if (isResolved) isLoading(false);
    setValue(e?.target?.value);
  };

  return (
    <div className={props.container_classes}>
      {loading ? (
        <div className="animate-pulse text-md font-medium py-1 leading-6 text-center h-full">
          Processing...
        </div>
      ) : (
        <>
          {props.showLabel && (
            <label className="block text-sm font-medium leading-6 text-gray-900">
              {props.label}
            </label>
          )}
          <div className="rounded-md shadow-sm ring-1 ring-gray-300">
            <select
              value={value}
              onChange={handleValue}
              name={props.name}
              id={props.name}
              className="w-full border-0 px-1 py-2 bg-transparent text-gray-900 placeholder:text-gray-400  sm:text-sm "
            >
              {props.showFirstOption && <option value="">{props.label}</option>}
              {props.options.map((option, index) => {
                return (
                  <option
                    value={option.key}
                    key={index}
                  >
                    {option.value }
                  </option>
                );
              })}
            </select>
          </div>
        </>
      )}
    </div>
  );
};
