// utils/permissions.js
export const hasPermission = (permissions, channelId, permissionType, isAdmin) => {
  // If the user is an admin, grant all permissions
  if (isAdmin) {
    return true;
  }

  // Otherwise, check for specific permissions in the channel
  const channelPermissions = permissions.find(
      (perm) => perm.channel_id === channelId
  );
  return channelPermissions ? channelPermissions[permissionType] : false;
};
