import { useEffect, useState } from "react";
import { getUserById } from "../../../routes/api";
import { useNavigate, useParams } from "react-router-dom";

export default function SingleUser() {
    const params = useParams();
    const [user, setUser] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        getUserById(params.id).then((res) => {
            setUser(res.data.data);
        });
    }, []);

    return (
        <div>
            <div className="flex mb-6"> {/* Added mb-4 for margin bottom */}
                <button
                    type="button"
                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={() => navigate("/auth/dashboard/users")}
                >
                    Back
                </button>
            </div>
            <div className="px-4 sm:px-0">
                <h3 className="text-base font-semibold leading-7 text-gray-900">User Data</h3>
                <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">User Info and Channel Permissions</p>
            </div>
            <div className="mt-6">
                <dl className="grid grid-cols-1 sm:grid-cols-2">
                    <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Name</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{user?.name}</dd>
                    </div>
                    <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Email</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{user?.email}</dd>
                    </div>
                </dl>
            </div>

            <h2 className="font-bold text-lg mt-4">Channel Permissions</h2>
            <div className="grid grid-cols-1 gap-4 mt-2">
                {user?.permissions?.map((permission) => (
                    <div key={permission.channel_id} className="flex items-center justify-between border p-4 rounded-md">
                        <span className="font-semibold">{permission.channel_name}</span>
                        <div className="flex space-x-4">
                            <div className="flex items-center space-x-2">
                                <span>Can Create</span>
                                <input type="checkbox" checked={permission.can_create_play} readOnly />
                            </div>
                            <div className="flex items-center space-x-2">
                                <span>Can Edit</span>
                                <input type="checkbox" checked={permission.can_edit_play} readOnly />
                            </div>
                            <div className="flex items-center space-x-2">
                                <span>Can Delete</span>
                                <input type="checkbox" checked={permission.can_delete_play} readOnly />
                            </div>
                            <div className="flex items-center space-x-2">
                                <span>Can View</span>
                                <input type="checkbox" checked={permission.can_view_play} readOnly />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
