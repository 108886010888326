import { Outlet } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import useAuthSidebarOptions from '../utils/useAuthSidebarOptions'; // Use the correct hook here
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

export default function Dashboard() {
  const dispatch = useDispatch();
  useEffect(() => {
    // console.log("dashboard");
  }, []);

  const [isExpanded, setIsExpanded] = useState(true);
  const authSidebarOptions = useAuthSidebarOptions(); // Get options from the hook

  const handleIsExpanded = () => setIsExpanded(!isExpanded);

  return (
      <>
        <div className="flex transition">
          <div className={`relative`}>
            <Sidebar
                options={authSidebarOptions} // Ensure this is defined
                isExpanded={isExpanded}
                handleIsExpanded={handleIsExpanded}
            />
          </div>
          <div className={`w-full p-10`}>
            <Outlet />
          </div>
        </div>
      </>
  );
}
