import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import moment from "moment";
import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";

export default function Calendar({ onSelect }) {
  const [isCalendarShowing, showCalendar] = useState(false);
  const calendarRef = useRef(null);
  const { start_date, end_date } = useSelector((state) => state.search);

  const selectionRange = {
    startDate: moment(start_date).toDate(),
    endDate: moment(end_date).toDate(),
    key: "selection",
  };

  const handleClickOutside = (event) => {
    if (calendarRef.current && !calendarRef.current.contains(event.target)) {
      showCalendar(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
      <div className="relative">
        <div className="flex items-center border border-l-0 border-r-0 border-gray-300 rounded">
        <span
            className="p-1 w-full border border-t-0 border-b-0 bg-white mr-2 hover:bg-gray-200 transition-all cursor-pointer"
            onClick={() => showCalendar(!isCalendarShowing)}
        >
          {start_date} / {end_date}
        </span>
        </div>
        {isCalendarShowing && (
            <div ref={calendarRef}>
              <DateRangePicker
                  className="absolute right-0 top-14 border border-gray-400 text-black bg-white z-40"
                  ranges={[selectionRange]}
                  onChange={onSelect}
                  showSelectionPreview={false}
                  displayMode="dateRange"
                  showDateDisplay={false}
                  staticRanges={[]}
                  inputRanges={[]}
                  fixedHeight={true}
                  moveRangeOnFirstSelection={true}
                  editableDateInputs={true}
              />
            </div>
        )}
      </div>
  );
}
