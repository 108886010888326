import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { toast } from 'react-toastify';
import {saveChannel, savePlay} from "../../../routes/api";
import { useNavigate } from "react-router-dom";

export default function AddChannel() {
  const navigate = useNavigate();

  useEffect(() => {
  }, []);

  const initialValues = {
    name: "",
    telegram_chat_id: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string()
        .required("Channel is required"),
  });


  // Form submission handler
  const onSubmit = (values, { setSubmitting, resetForm }) => {
    console.log('form: ', values);

    saveChannel(values).then((res) => {
      if (res.data.status === "success") {
        toast.success(res.data.message);
        // Clear the form
        resetForm();
        navigate("/auth/dashboard/channels", { replace: true });
      }
    }).catch((error) => {
      if (error.response && error.response.status === 422) {
        // Validation errors
        const validationErrors = error.response.data.errors;
        for (const [field, messages] of Object.entries(validationErrors)) {
          // Show error messages for each field
          // messages.forEach(message => toast.error(`${field}: ${message}`));
          messages.forEach(message => toast.error(`${message}`));
        }
      } else {
        // Other errors
        toast.error('An error occurred. Please try again.');
      }

    });

    setSubmitting(false); // Reset isSubmitting to false after submission
  };


  return (
      <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
            <Form>
              <h1 className="font-bold text-xl">Add New Channel</h1>
              <div className="grid grid-cols-3 gap-4 mt-4">

                <div className="col-span-1">
                  <label htmlFor="name" className="form-label">Name</label>
                  <Field
                      type="text"
                      name="name"
                      id="name"
                      className="form-control"
                  />
                </div>

                <div className="col-span-1">
                  <label htmlFor="telegram_chat_id" className="form-label">Telegram Chat ID</label>
                  <Field
                      type="number"
                      name="telegram_chat_id"
                      id="telegram_chat_id"
                      className="form-control"
                  />
                </div>

                <div className="col-span-1">
                  <label htmlFor="signal_id" className="form-label">Signal Group ID</label>
                  <Field
                      type="number"
                      name="signal_id"
                      id="signal_id"
                      className="form-control"
                  />
                </div>

              </div>


              <div className="flex items-center justify-start gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
                <button
                    type="submit"
                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Add
                </button>
                <button
                    type="button"
                    className="text-sm font-semibold leading-6 text-gray-900"
                    onClick={() => navigate("/auth/dashboard/channels")}
                >
                  Cancel
                </button>
              </div>
            </Form>

        )}

      </Formik>
  );
}
