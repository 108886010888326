import { useEffect, useState } from "react";
import {getChannelById} from "../../../routes/api";
import { useNavigate, useParams } from "react-router-dom";

export default function SingleChannel() {

    const params = useParams();
    const [channel, setChannel] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        getChannelById(params.id).then(res => {
            setChannel(res.data.data)
        })
    }, []);

  return (
    <div>
        <div className="flex mb-6"> {/* Added mb-4 for margin bottom */}
            <button
                type="button"
                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={() => navigate("/auth/dashboard/channels")}
            >
                Back
            </button>
        </div>
      <div className="px-4 sm:px-0">
        <h3 className="text-base font-semibold leading-7 text-gray-900">Channel Data</h3>
        <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Channel Info and Finances</p>
      </div>
      <div className="mt-6">
        <dl className="grid grid-cols-1 sm:grid-cols-2">
          <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Name</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{channel?.name}</dd>
          </div>
          <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Telegram Chat ID</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{channel?.telegram_chat_id}</dd>
          </div>
          <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Signal Group ID</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{channel?.signal_id}</dd>
          </div>
          <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Channel Owner</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{channel?.owner.name}</dd>
          </div>
        </dl>
      </div>
    </div>
  )
}
