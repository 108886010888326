import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {getChannelById, updateChannel} from "../../../routes/api";
import { toast } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";

export default function EditChannel() {
  const params = useParams();
  const navigate = useNavigate();

  const [initialValues, setInitialValues] = useState({
    name: '',
    telegram_chat_id: '',
    signal_id: '',
  });

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
  });

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    updateChannel(params.id, values)
        .then((res) => {
          if (res.data.status === "success") {
            toast.success(res.data.message);
            resetForm();
            navigate("/auth/dashboard/channels", { replace: true });
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 422) {
            const validationErrors = error.response.data.errors;
            for (const [field, messages] of Object.entries(validationErrors)) {
              messages.forEach((message) => toast.error(`${message}`));
            }
          } else {
            toast.error('An error occurred. Please try again.');
          }
        });
    setSubmitting(false);
  };

  useEffect(() => {
    const fetchChannel = async () => {
      try {
        const res = await getChannelById(params.id);
        const data = res.data.data;

        setInitialValues({
          id: data.id,
          name: data.name,
          telegram_chat_id: data.telegram_chat_id,
          signal_id: data.signal_id,
        });
      } catch (error) {
        console.error('Error fetching channel:', error);
      }
    };

    fetchChannel();
  }, [params.id]);

  return (
      <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize // Allow Formik to reinitialize when initialValues change
      >
        {({ values }) => (
            <Form>
              <h1 className="font-bold text-xl">Edit Channel {initialValues.id}</h1>
              <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                <div className="grid grid-cols-3 gap-4 mt-4">

                  <div className="col-span-1">
                    <label htmlFor="name" className="form-label required">Name</label>
                    <Field type="text" name="name" id="name" className="form-control" />
                    <ErrorMessage name="name" component="div" className="form-error" />
                  </div>

                  <div className="col-span-1">
                    <label htmlFor="telegram_chat_id" className="form-label">Telegram Chat ID</label>
                    <Field type="text" name="telegram_chat_id" id="telegram_chat_id" className="form-control" />
                    <ErrorMessage name="telegram_chat_id" component="div" className="form-error" />
                  </div>

                  <div className="col-span-1">
                    <label htmlFor="signal_id" className="form-label">Signal Group ID</label>
                    <Field type="text" name="signal_id" id="signal_id" className="form-control" />
                    <ErrorMessage name="signal_id" component="div" className="form-error" />
                  </div>
                </div>

                <div className="flex items-center justify-start gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
                  <button
                      type="submit"
                      className={'rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 bg-indigo-600 hover:bg-indigo-500'}
                  >
                    Save
                  </button>
                  <button
                      type="button"
                      className="text-sm font-semibold text-gray-900"
                      onClick={() => navigate("/auth/dashboard/channels")}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Form>
        )}
      </Formik>
  );
}
