import { useEffect, useState } from "react";
import {getPlayById} from "../../../routes/api";
import { useNavigate, useParams } from "react-router-dom";
import {formatNumber} from "../../../utils/helpers";

export default function SingleFigure() {

    const params = useParams();
    const [data, setData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        getPlayById(params.id).then(res => {
            setData(res.data.data)
        })
    }, []);

  return (

    <div>

      <div className="px-4 sm:px-0">
          <div className="flex mb-6"> {/* Added mb-4 for margin bottom */}
              <button
                  type="button"
                  className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  onClick={() => navigate("/auth/dashboard/plays")}
              >
                  Back
              </button>
          </div>

          <h3 className="text-base font-semibold leading-7 text-gray-900">Play Data</h3>

        <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Full Play Details</p>
      </div>
      <div className="mt-6">
        <dl className="grid grid-cols-1 sm:grid-cols-2">
          <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Play</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{data?.play}</dd>
          </div>
          <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Date</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{data?.date}</dd>
          </div>
          <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Channel</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{data?.channel?.name}</dd>
          </div>
          <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Play Creator</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{data?.creator?.name}</dd>
          </div>
          <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Odds</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{formatNumber(data?.odds, 0)}</dd>
          </div>
          <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Risk Amount</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{formatNumber(data?.risk_amount, 0)}</dd>
          </div>
          <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Win Amount</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{formatNumber(data?.win_amount, 0)}</dd>
          </div>
          <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Result</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{data?.outcome ? formatNumber(data?.result, 0) : 'No Result Yet'}</dd>
          </div>
          <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Outcome</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{data?.outcome ? data?.outcome : 'Game Not Finished'}</dd>
          </div>
          <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Posted to Telegram</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{data?.posted_to_telegram ? (
                <span style={{ color: 'green' }}>✅</span> // Green Tick for true
            ) : (
                <span style={{ color: 'red' }}>❌</span>  // Red Cross for false
            )}</dd>
          </div>
          <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Posted to Signal</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{data?.posted_to_signal ? (
                <span style={{ color: 'green' }}>✅</span> // Green Tick for true
            ) : (
                <span style={{ color: 'red' }}>❌</span>  // Red Cross for false
            )}</dd>
          </div>
          {/*<div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">*/}
          {/*  <dt className="text-sm font-medium leading-6 text-gray-900">Notes</dt>*/}
          {/*  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{data?.notes}</dd>*/}
          {/*</div>*/}
        </dl>
      </div>
    </div>

  )
}
